import React from "react";
import { Container, Image, Button, Card, Row, Col} from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import H1 from "../images/wrks.png"
import W1 from "../images/works01.jpg"

console.log(H1); // MV image
console.log(W1); // MV image

const WorksPage = () => (
  <Layout>
    <SEO title="Works" />
    <Image src={H1} fluid />
    <br></br><br></br>
    <Container>
    <Card className="text-center">
    <Card.Img variant="top" src={W1} />
    <Card.Body>
    <Card.Title>Japanese manga artist Muraken<br></br>Website Produce,Develop,Management</Card.Title>
      <Card.Text>
      Japanese manga artist MURAKEN with 34,000 followers on his Facebook page.<br></br>
      He updates his four-frame manga about Vietnam mainly online.<br></br>
      Hitsville V.N. is in charge of the entire process from producing his Website to building and managing it.
      </Card.Text>
      <Button align="right" size="lg" variant="outline-danger" href="https://muraken.vn/" target="_blank">Muraken Official Site</Button>{' '}
    </Card.Body>
  </Card>
</Container>
  </Layout>
);
export default WorksPage;
